<script setup lang="ts">
import useCustomSearchVisibility from "~/composables/useCustomSearchVisibility";
import {onClickOutside, useFocus, useMagicKeys} from "@vueuse/core";
import SearchSuggestions from "~/components/layout/SearchSuggestions.vue";

const {observeElement, disconnectObserver, isCustomSearchVisible} = useCustomSearchVisibility();

const props = defineProps<{
    content: any;
}>();

const searchInput = ref<HTMLInputElement|null>(null);
const searchContainer = ref<HTMLInputElement|null>(null);

// True when the search bar is active and user can type in the search field
const active = inject("searchActive", ref(false));
const typingQuery = inject('searchQuery') as Ref<string>;
const {enter} = useMagicKeys({target: searchInput});
const isSideMenuOpened = inject("isSideMenuOpened", ref(false));

const submitSearch = () => {
    navigateTo({
        path: "/search",
        query: { query: typingQuery.value }
    });
}

const emits = defineEmits<{
    (e: "click-away"): void;
}>();

const showSuggest = computed(() => {
    return typingQuery.value.length >= 1
        && active.value
        && isCustomSearchVisible();
});

watch(enter, (value) => {
    if (!value) return;

    isSideMenuOpened.value = false;

    active.value = false;
    navigateTo("/search?query=" + typingQuery.value);
});

watch(active, (value) => {
    const {focused} = useFocus(searchInput);

    focused.value = value;
});

onMounted(() => {
    observeElement(searchInput.value);

    onClickOutside(searchContainer, () => {
        active.value = false;
        emits("click-away");
    });
});

onUnmounted(() => {
    disconnectObserver();
});
</script>

<template>
    <div class="container px-[20px] relative" ref="searchContainer">
        <div
            class="element-custom-search bg-white px-[12px] pb-[26px] pt-[19px] lg:p-[35px] rounded-3 absolute absolute-center z-2 top-[-30px]"
            style="box-shadow: 0px 6px 10px #0000000D;"
        >
            <div class="text-[18px] lg:text-[25px] mb-[25px] font-semibold w-full text-center">{{content.config.title.value}}</div>
            <div class="relative">
                <input
                    ref="searchInput"
                    v-model="typingQuery"
                    type="search"
                    autocomplete="off"
                    :placeholder="$t('search.searchPlaceholder')"
                    class="w-full bg-brand-gray text-[15px] lg:text-[18px] text-ellipsis font-normal outline-none pb-[17px] pt-[20px] px-[30px] rounded-full border-none"
                    @keydown.enter="submitSearch"
                    @click="active = true"
                />
                <img
                    src="/search.svg" height="20"
                    :alt="$t('search.searchPlaceholder')"
                    class="absolute top-[50%] right-[30px] transform -translate-y-1/2 cursor-pointer"
                    @click.prevent="submitSearch"
                />
                <div
                    v-if="showSuggest"
                    id="searchSuggestItems"
                    class="absolute bg-white border-gray-100 border-t-1 left-0 duration-300 mt-2 overflow-hidden shadow-md transition-height w-full md:mt-4 z-10"
                >
                    <SearchSuggestions :query="typingQuery"/>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>

.element-custom-search {
    width: calc(100% - 40px);

    @media (min-width: 1024px) {
        width: 50%;
    }
}

</style>
